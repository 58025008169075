import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from '@mui/material/styles';

const titleStyling = {
  fontFamily: 'DM Sans',
  fontSize: {
    xs: '16px',
    sm: '24px',
    md: '32px',
  },
  color: 'primary.main',
  opacity: 0.8,
  lineHeight: {
    xs: 1,
    md: 1.5,
  },
}

const paragraphStyling = {
  fontFamily: 'DM Sans',
  fontSize: {
    xs: '8px',
    sm: '16px',
    md: '20px',
  },
  color: 'primary.main',
  opacity: 0.8,
  lineHeight: {
    xs: 1,
    md: 1.5,
  },
}

// Define the props type
interface BlogItemProps {
  title: string;
  picture: string;
  abstract: string;
  url: string;
  bottom_boarder: boolean;
}

// Create the BlogItem component
const BlogItem: React.FC<BlogItemProps> = ({ title, picture, abstract, url, bottom_boarder }) => {
  const theme = useTheme();

  // Optional click handler if you use React Router or similar
  const handleReadHere = () => {
    window.location.href = url;
  };

  return (
    // Subtle hover scaling
    <article>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {  md: 'row' },
          alignItems: 'flex-start',
          mb: 2,
          pb: 2,
          ml: { sm: 2, md: 10},
          color: theme.palette.common.white,
        }}
      >
        {/* Blog Image */}
        <Box
          component="img"
          src={picture}
          alt={title}
          sx={{
            width: { xs: '20%', md: 150 },
            height: { xs: 'auto', md: 'auto' },
            objectFit: 'cover',
            mr: { xs: 0, md: 10 },
            mb: { xs: 2, md: 0 },
            ml: { xs: 2, md: 0 },
          }}
        />

        {/* Text content */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 4, marginRight: 4 }}>
          <Box>
            <Typography variant="h5" gutterBottom sx={titleStyling}>
              {title}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, ...paragraphStyling, mt : 3 }}>
              {abstract}
            </Typography>
          </Box>

          {/* "Read Here" Button */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end',mr: 2 }}>
            <Button
              variant="text"
              endIcon={
                <ArrowForwardIosIcon
                  sx={{ fontSize: 16, color: theme.palette.primary.main }}
                />
              }
              sx={{ display: 'flex', alignItems: 'center', textTransform: 'none' }}
              onClick={handleReadHere}
            >
              <Typography
                variant="body1"
                sx={{ ...paragraphStyling, textAlign: 'right', color: theme.palette.secondary.main }}
              >
                Read More
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      {bottom_boarder && (
        <Box
          sx={{
            width: '95%',
            borderTop: '1px solid',
            borderColor: theme.palette.primary.main,
            // 'linear-gradient(90deg, #7A4799 16%, #CB77FF 47%, #5C007C 84%)',
            mt: 4,
            mb: 4,
            mx: 'auto', // Centers the border horizontally
          }}
        />
      )}
    </article>
  );
};

export default BlogItem;