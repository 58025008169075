import { Box, Typography, useMediaQuery } from '@mui/material';
interface ReasonsSectionProps {
}

const h1Style = {
    fontSize: {
        xs: '24px',
        sm: '32px',
        md: '40px',
    },
    fontFamily: 'Signika',
    color: 'primary.main',
};

const ReasonsSection: React.FC<ReasonsSectionProps> = () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Box
            sx={{
                position: 'relative',
                overflow: 'visible',
                width: '100%',
                minHeight: '500px',
                mt: isMobile ? 10 : 10
            }}
        >
            {/* /* Background Image */}
            <Box
                component="img"
                src="ReasonsBackground.jpg"
                alt="Home Page Top Banner"
                sx={{
                    display: 'block',
                    width: 'auto',
                    height: '100%',
                    opacity: '60%',
                    objectFit: 'cover',
                    objectPosition: 'bottom right',
                }}
            />

            {/* Overlay Container (positioned above background) */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 0,
                }}
            >
                {/* Left Column: White box overlay */}
                <Box
                    sx={{
                        width: { xs: '100%', md: '60%' },
                        bgcolor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: 5,
                        pb: 5,
                        pl: 4,
                        pr: 4,
                    }}
                >
                    {/* Container for your 4 rows */}
                    <Box
                        sx={{
                            ml: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            mr: 4,
                        }}
                    >
                        {/* Row 1: Title */}
                        <Typography variant="h1" sx={{ ...h1Style, fontSize: {xs: 20, md:40}, textAlign: 'center' }}>
                            A Few Reasons You Want Me
                        </Typography>
                        {/* Row 1: Title */}
                        <Typography variant="h1" sx={{ ...h1Style, fontSize: {xs: 20, md:40}, color: 'secondary.main', textAlign: 'center' }}>
                            In Your Corner
                        </Typography>

                        {/* Row 2: Subtitle */}
                        <Typography variant="subtitle1" sx={{ mt: 3, fontFamily: 'DM Sans', fontSize: {xs: 10, md:20}, fontWeight: 'Medium', pb: 0, color: 'primary.main' }}>
                            &gt;&gt; It&rsquo;s not just about tech—it&rsquo;s about trust.
                        </Typography>
                        <Typography variant="subtitle1" sx={{
                            fontFamily: 'DM Sans',
                            fontSize: {xs: 8, md:16},
                            fontWeight: 'Medium',
                            ml: 2,
                            mr: 4,
                            color: 'secondary.main'
                        }}>
                            I see every project as a partnership rooted in communication and understanding. From our first conversation to post-project support, I’ll ensure you feel informed, supported, and empowered to achieve your goals.
                        </Typography>

                        <Typography variant="subtitle1" sx={{ mt: 3, fontFamily: 'DM Sans', fontSize: {xs: 10, md:20}, fontWeight: 'Medium', pb: 0, color: 'primary.main' }}>
                            &gt;&gt; A fresh perspective in a traditionally old-school industry.
                        </Typography>
                        <Typography variant="subtitle1" sx={{
                            fontFamily: 'DM Sans',
                            fontSize: {xs: 8, md:16},
                            fontWeight: 'Medium',
                            ml: 2,
                            mr: 4,
                            color: 'secondary.main'
                        }}>
                            Let&rsquo;s face it—tech can feel impersonal and, well, outdated. As a female founder, I bring a modern, people-first perspective to every project. No confusing jargon, no outdated methods—just innovative solutions designed with care.
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mt: 3, fontFamily: 'DM Sans', fontSize: {xs: 10, md:20}, fontWeight: 'Medium', pb: 0, color: 'primary.main' }}>
                            &gt;&gt; I&rsquo;ve been walking the walk for over a decade.
                        </Typography>
                        <Typography variant="subtitle1" sx={{
                            fontFamily: 'DM Sans',
                            fontSize: {xs: 8, md:16},
                            fontWeight: 'Medium',
                            ml: 2,
                            mr: 4,
                            color: 'secondary.main'
                        }}>
                            From leading UX teams to building software at industry-leading companies, I&rsquo;ve spent years developing a proven track record of creating solutions that scale with success. I bring this experience to every project, big or small.
                        </Typography>


                    </Box>
                </Box>
                {/* Right Column: Image on top of the background */}
                <Box
                    component="img"
                    src="ReasonsSydneyPic.jpg"
                    alt="Sydney Founder"
                    sx={{
                        height: {xs:'10%', md:'50%'},
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1,
                        ml: -4
                    }}
                >
                </Box>

            </Box>
        </Box>
    );
}


export default ReasonsSection;