import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { motion } from 'framer-motion';

export interface stepText{
    title: string;
    description: string;
}

interface ProcessSectionProps {
    h1Style: any;
    stepText: stepText[];
}

const StepTitleStyle = {
    fontSize: {
        xs: '12px',
        sm: '16px',
        md: '20px',
    },
    fontFamily: 'DM Sans',
    lineHeight: {
        xs: 1,
        md: 1.5,
        lg: 1.5
    },
    color: 'primary.main',
    fontWeight: 'bold',
    mb: 1
};

const StepTextStyle = {
    fontFamily: 'DM Sans',
    fontSize: {
        xs: '12px',
        sm: '14px',
        md: '16px',
    },
    lineHeight: 1.1,
    color: 'primary.main',
    opacity: 0.8
};

const lineWeight = 3;


const ProcessSection: React.FC<ProcessSectionProps> = ({ h1Style,stepText }) => {
    return (
        <Box sx={{ backgroundColor: 'white', textAlign: 'center', mt: 0, ml: 1, mr: 1, pr: 4, pl: 4, mb: {xs:0, md:10} }}>
            <Typography variant="h4" sx={{ ...h1Style, color: 'primary.main', mb: 5 }}>
                Our Process
            </Typography>
            <Grid container >
                <Grid size={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        transition={{ duration: 0.3 }}
                    >
                        <Typography sx={{ ...StepTitleStyle }}>
                            {stepText[0].title}
                        </Typography>
                        <Typography sx={StepTextStyle}>
                            {stepText[0].description}
                        </Typography>
                    </motion.div>

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ borderLeft: lineWeight, borderColor: 'secondary.main', height: '50px', mt: 1 }} />
                    </Box>
                </Grid>
                <Grid size={2} />
                <Grid size={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        transition={{ duration: 0.3 }}
                    >
                        <Typography sx={{ ...StepTitleStyle }}>
                            {stepText[2].title}
                        </Typography>
                        <Typography sx={StepTextStyle}>
                            {stepText[2].description}
                        </Typography>
                    </motion.div>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ borderLeft: lineWeight, borderColor: 'secondary.main', height: '50px', mt: 1 }} />
                    </Box>
                </Grid>
                <Grid size={2} />
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 0 }}>
                <Box sx={{ borderBottom: lineWeight, borderColor: 'secondary.main', width: '100%' }} />
            </Box>
            <Grid container >
                <Grid size={2} />
                <Grid size={4} sx={{ margin: '0 auto', textAlign: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ borderLeft: lineWeight, borderColor: 'secondary.main', height: '50px', mt: 0 }} />
                    </Box>
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        transition={{ duration: 0.3 }}
                    >
                        <Typography sx={{ ...StepTitleStyle }}>
                            {stepText[1].title}
                        </Typography>
                        <Typography sx={StepTextStyle}>
                            {stepText[1].description}
                        </Typography>
                    </motion.div>
                </Grid>
                <Grid size={2} />
                <Grid size={4}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ borderLeft: lineWeight, borderColor: 'secondary.main', height: '50px', mt: 0 }} />
                    </Box>
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        transition={{ duration: 0.3 }}
                    >
                        <Typography sx={{ ...StepTitleStyle }}>
                            {stepText[3].title}
                        </Typography>
                        <Typography sx={StepTextStyle}>
                            {stepText[3].description}
                        </Typography>
                    </motion.div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProcessSection;
