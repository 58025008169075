import { Box, Typography, Button } from '@mui/material';


export interface PackageItem {
  title: string;
  description: string;
};

interface PackageSectionProps {
  h2Style: any;
  packageItems: PackageItem[];
  imageSrc: string;
  title: string;
  secondaryTitle: string;
  imageWidth: any;
}

const BULLET_ITEM = '>>';

const PackagesSection: React.FC<PackageSectionProps> = ({ h2Style, packageItems,imageSrc,title,secondaryTitle,imageWidth }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'row', md: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        mt: {xs:'-40%',md:'-40%'},
        mb: {xs:1,md:10},
        minHeight: { xs: '500px', md: '800px', lg: '800px' }, // Make lg the same as md
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'block',
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
          opacity: 0.15,
          zIndex: 1,
          backgroundColor: 'secondary.main',
          minHeight: { xs: '500px', md: '800px', lg: '800px' }, // Make lg the same as md
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <Box
        sx={{
          width: { xs: '70%', md: '50%', lg: '50%' }, // Make lg the same as md
          height: { xs: '400px', md: '600px', lg: '650px' }, // Make lg the same as md
          bgcolor: 'white',
          zIndex: 2,
          paddingRight: { xs:2, md: 3, lg: 3 }, // Make lg the same as md
          paddingLeft: { xs:1, md: 3, lg: 3 }, // Make lg the same as md
          ml:{xs:4,md:0},
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography sx={{ ...h2Style, color: 'primary.main', textAlign: 'left', mt: 2 }}>
          {title}
        </Typography>
        <Typography sx={{ ...h2Style, lineHeight: { sm: 1.2, md: 1.0, lg: 1.0 }, color: 'secondary.main', textAlign: 'left', mt: 0 }}>
          {secondaryTitle}
        </Typography>
        {packageItems.map((item, index) => (
          <>
            <Typography key={`title_${index}`} variant="subtitle1" sx={{ mt: 3, fontFamily: 'DM Sans', fontSize: { xs: 10, md: 20 }, fontWeight: 'Medium', pb: 0, color: 'primary.main' }}>
              {BULLET_ITEM} {item.title}
            </Typography>
            <Typography key={`sentence_${index}`} variant="subtitle1" sx={{
              fontFamily: 'DM Sans',
              fontSize: { xs: 8, md: 12, lg: 12 }, // Make lg the same as md
              fontWeight: 'Medium',
              ml: { md: 1, lg: 1 }, // Make lg the same as md
              mr: { md: 2, lg: 2 }, // Make lg the same as md
              color: 'secondary.main'
            }}>
              {item.description}
            </Typography></>))}

        <Box sx={{ mt: { xs: 1, md: 4, lg: 10 }, display: 'flex', justifyContent: 'center' }}>
            <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ padding: { xs: '2px 4px', md: '4px 8px' }, fontSize: { xs: '8px', md: '12px' } }}
            href="/contact"
            >
            Start Your Project
            </Button>
        </Box>
      </Box>
      <Box
        component="img"
        src={imageSrc}
        alt="Example"
        sx={{
          width: imageWidth,
          height: 'auto',
          ml: { xs: -1, md: -2 },
          zIndex: 3,
        }} />
    </Box>

  );
}


export default PackagesSection;