import React from 'react';
import { Helmet } from 'react-helmet-async';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import {
  Box,
} from '@mui/material';
import ReasonsSection from '../components/AboutComponenets/ReasonsSection';
import SydneySection from '../components/AboutComponenets/SydneySection';
import BehindTechSection from '../components/AboutComponenets/BehindTheTechSection';
import LetsGetStartedSection from '../components/AboutComponenets/LetsGetStartedSection';
import AboutUsQuotes from '../components/AboutComponenets/AboutUsQuotes';

// BitBlossom_Logo_Icon.PNG
const h1Style = {
  fontSize: {
    xs: '20px',
    sm: '32px',
    md: '40px',
  },
  fontFamily: 'Signika',
  color: 'primary.main',
  lineHeight: {
    xs: 1,
    md: 1.5,
  },
};

const paragraphStyle = {
  fontFamily: 'DM Sans',
  fontSize: {
    xs: '14px',
    sm: '18px',
    md: '20px',
  },
  lineHeight: 1.4,
};

const About: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Bit Blossom Software</title>
        <meta
          name="description"
          content="Bit Blossom Software provides heart-centered software solutions in Fargo, ND. Empowering businesses with compassionate, tailored technology solutions."
        />
      </Helmet>

      <AppBarWithLogo
        logoAlt="Bit Blossom Software Logo"
        logoSrc={`BitBlossom_Logo_Hztl_cropped.png`}
        appName="Bit Blossom Software"
        useDark={false}
      />
      <Box sx={{ overflowX: 'hidden' }}>
        {/* Sydney Section */}
        <SydneySection h1Style={h1Style} paragraphStyle={paragraphStyle} />
        {/* Tech Made Simple Section */}
        <AboutUsQuotes h1Style={h1Style} paragraphStyle={paragraphStyle} />

        { /* Reasons in your corner section */}
        <ReasonsSection />
        <BehindTechSection h1Style={h1Style} paragraphStyle={paragraphStyle} />
        <LetsGetStartedSection h1Style={h1Style} paragraphStyle={paragraphStyle} />
        <BottomNav useDark={true} />
      </Box>
    </>
  );
};

export default About;
