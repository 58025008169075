import { Box, Typography, useMediaQuery } from '@mui/material';
interface SydneySectionProps {
    h1Style: any;
    paragraphStyle: any;
}

const SydneySection: React.FC<SydneySectionProps> = ({h1Style, paragraphStyle}) => {
    const isMobile = useMediaQuery('(max-width:600px)');
  const mlText = isMobile ? 0 : -14;
    return (
        <Box >
          {/* I'm Sydney Section */}
          <Box style={{ position: 'relative', overflow: 'visible', width: '100%', minHeight: '500px' }}>
            {/* background color */}
            <Box
              sx={{
                display: 'block',
                width: '100%',
                minHeight: '600px',
                backgroundColor: 'secondary.main',
                opacity: 0.1,
              }}
            />
            {/* background B */}
            <Box
              component="img"
              src="BitBlossom_Logo_Icon.PNG"
              alt="Bit Blossom Software B"
              sx={{
                position: 'absolute',
                top: {xs:'25%' , md:'15%'},
                left: {xs:'2.5%' , md:'10%'},
                width: { xs: 75, md: 150 },
                height: 'auto',
                mr: { xs: 2, md: 4 },
                mb: { xs: 2, md: 4 },
                opacity: 0.25,
              }}
            />
            {/* Overlay Container (positioned above background) */}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                paddingLeft: {xs:'1%', md:'18%'},
              }}
            >
              {/* Left Column: Image on top of the background */}
              <Box
                component="img"
                src="AboutSydney1.jpg"
                alt="Sydney Founder"
                sx={{
                  height: { xs:'15%', md:'55%'},
                  maxHeight: '400px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mr: '-4%',
                  mt: '12%',
                  zIndex: 0,
                  maxWidth: '30%',
                }}
              >
              </Box>

              {/* Right Column: White box overlay */}
              <Box
                component="img"
                src="AboutSydney2.jpg"
                alt="Sydney Founder With Laptop"
                sx={{
                  height: { xs: '40%', md: '100%' },
                  maxHeight: '500px',
                  width: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: 5,
                  pb: 5,
                }}
              >

              </Box>
              <Box
                sx={{
                  ml: mlText,
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  mr: 4,
                  justifyContent: 'flex-start',
                  alignSelf: 'flex-start',
                  mt: '3%',
                }}
              >
                <Box sx={{ textAlign: 'right', alignSelf: 'flex-start', }}>
                  <Typography variant="h1" sx={{ ...h1Style, color: 'secondary.main' }}>
                    Hi there!
                  </Typography>
                  <Typography variant="h1" sx={{ ...h1Style, color: 'primary.main', fontSize: { sm:20, md:60}, lineHeight: 0.75 }}>
                    I&rsquo;m Sydney
                  </Typography>
                </Box>
                <Typography variant="h1" sx={{ ...paragraphStyle, color: 'primary.main', textAlign: 'left', mt: {xs:4, md:5}, alignSelf: 'flex-start', ml: { xs:2, md:18 } }}>
                  I simplify tech and create stunning websites, custom software, and IT
                  solutions that empower your business to grow. Together, we&rsquo;ll make
                  technology work for you—so you can focus on what you do best.
                </Typography>
                <Typography variant="h1" sx={{ ...paragraphStyle, color: 'primary.main', textAlign: 'left', mt: 5, alignSelf: 'flex-start', ml: { xs:2, md:18 } }}>
                  <strong>I&rsquo;m into:</strong>
                </Typography>
                <Typography variant="h1" sx={{ ...paragraphStyle, color: 'secondary.main', textAlign: 'left', mt: 0, alignSelf: 'flex-start', ml: { xs:2, md:18 } }}>
                  &gt;&gt; Biking along the river<br />
                  &gt;&gt; All things purple<br />
                  &gt;&gt; Camping across the US
                </Typography>
              </Box>
            </Box>

            {/* Lower right badge */}
            <Box
              component="img"
              src="BitBlossom_Logo_Badge.png"
              alt="Bit Blossom Software Badge"
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: { xs: 50, md: 150 },
                height: 'auto',
                mr: { xs: 2, md: 4 },
                mb: { xs: 2, md: 4 },
                opacity: 0.25,
              }}
            />
          </Box>
        </Box>
    );
}


export default SydneySection;