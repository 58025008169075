import { Box, Typography } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useState, useEffect } from 'react';

interface AboutUsQuotesProps {
    h1Style: any;
    paragraphStyle: any;
}

const quotes = [
    "Tech Made Simple, Solutions Made Just for You",
    "Building Connections Through Technology and Trust",
    "Simplifying Tech, Empowering Your Business"
];

const descriptions = [
    "Empowering businesses to thrive with technology that works seamlessly, so you can focus on what truly matters.",
    "Where innovation meets heart-centered solutions.",
    "Because technology should make things easier, not harder."
];

const AboutUsQuotes: React.FC<AboutUsQuotesProps> = ({ h1Style, paragraphStyle }) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % quotes.length);
        }, 5000); // Change quote every 5 seconds
        return () => clearInterval(interval);
    }, []);

    return (
        <Box sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', mt: 5, mb: 5 }}>
            <AnimatePresence mode='wait'>
                <motion.div
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                >
                    <Typography variant="h1" sx={{ ...h1Style }}>
                        {quotes[index]}
                    </Typography>
                    <Typography variant="h1" sx={{ ...paragraphStyle, mt: 2.5, color: 'primary.main', opacity: 0.80 }}>
                        {descriptions[index]}
                    </Typography>
                </motion.div>
            </AnimatePresence>
        </Box>
    );
}

export default AboutUsQuotes;