import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { ListItemText, Menu, MenuItem, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';

export const ServiceDropDownItems = [
  "Web Development",
  "Custom Software",
  // "IT Consulting",
  // "Tech Support",
];

interface AppBarWithLogoProps {
  logoAlt: string;
  logoSrc: string;
  appName: string;
  useDark: boolean;
}

const AppBarWithLogo: React.FC<AppBarWithLogoProps> = ({ logoAlt, logoSrc, appName, useDark }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [servicesOpen, setServicesOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const pages = ['About', 'Services', 'Blog', 'Contact'];
  const trigger = useScrollTrigger();
  const style = useDark ? {} : { background: 'white', boxShadow: 'none', borderBottom: '1px solid #EFECE5' };
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleServicesClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setServicesOpen(!servicesOpen);
  };

  const handleMenuItemClick = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0); 
    handleMenuClose();
    setDrawerOpen(false);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Box
          component="img"
          sx={{
            height: 55,
            margin: 1,
          }}
          alt="Bit Blossom Software Badge"
          src="BitBlossom_Logo_Badge.png"
        />
      </Link>
      <List>
        {pages.map((page, index) => (
          page === 'Services' ? (
            <React.Fragment key={`fragment_${page}`}>
              <ListItemButton
                onClick={handleServicesClick}
                sx={{
                  color: theme.palette.primary.main,
                  // Remove the background on focus and hover
                  '&.Mui-focusVisible': { backgroundColor: 'transparent' },
                  '&:hover': { backgroundColor: 'transparent' },
                }}
              >
                <ListItemText primary={page} />
                {servicesOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={servicesOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {ServiceDropDownItems.map((item, index) => (
                    <ListItemButton
                      key={`btn_${item}_${index}`}
                      onClick={() => {
                        handleMenuItemClick(
                          `/${item.toLowerCase().replace(/ /g, '-')}`
                        );
                        // Optionally, collapse the list after a click:
                        // setServicesOpen(false);
                      }}
                      sx={{
                        pl: 4,
                        color: theme.palette.primary.main,
                        '&.Mui-focusVisible': { backgroundColor: 'transparent' },
                        '&:hover': { backgroundColor: 'transparent' },
                      }}
                    >
                      <ListItemText primary={item} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>

          ) : (
            <ListItemButton key={`btn_${page}_${index}`} onClick={() => handleMenuItemClick(`/${page.toLowerCase()}`)}>
              <ListItemText key={`txt_${page}_${index}`} primary={page} sx={{ color: theme.palette.primary.main }} />
            </ListItemButton>
          )
        ))}
      </List>
    </Box>
  );

  return (
    <Slide in={!trigger}>
      <AppBar position="sticky" style={{ ...style }}>
        <Toolbar>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Box
              component="img"
              sx={{
                height: 55,
                margin: 1,
              }}
              alt={logoAlt}
              src={logoSrc}
            />
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          {isMobile ? (
            <>
              <IconButton
                color={useDark ? "inherit" : "primary"}
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerToggle}
              >
                {drawer}
              </Drawer>
            </>
          ) : (
            <Box sx={{ display: 'flex' }}>
              {pages.map((page) => (
                page === 'Services' ? (
                  <React.Fragment key={`fragment_${page}`}>
                    <Typography
                      key={`typography_${page}`}
                      variant="h6"
                      component="div"
                      fontWeight={useDark ? "" : "bold"}
                      sx={{ margin: theme.spacing(1), color: useDark ? theme.palette.common.white : theme.palette.primary.main, cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                      onClick={handleMenuOpen}
                    >
                      {page}
                      {Boolean(anchorEl) ? <ExpandLess /> : <ExpandMore />}
                    </Typography>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                      disableAutoFocusItem
                    >
                      {ServiceDropDownItems.map((item, index) => (
                        <MenuItem
                          key={`menuitem_${item}_${index}`}
                          autoFocus={false}
                          selected={false}
                          onClick={() => handleMenuItemClick(`/${item.toLowerCase().replace(/ /g, '-')}`)}
                          sx={{ color: theme.palette.primary.main, '& .MuiMenuItem-root': { backgroundColor: 'transparent' }, }}
                        >
                          <Typography
                            key={`menuitem_${item}_${index}`}
                            variant="h6"
                            component="div"
                            fontWeight={useDark ? "" : "bold"}
                            fontSize={14}
                            sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                          >
                            {item}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </React.Fragment>
                ) : (
                  <Typography
                    key={`typography_${page}`}
                    variant="h6"
                    component="div"
                    fontWeight={useDark ? "" : "bold"}
                    sx={{ margin: theme.spacing(1), color: useDark ? theme.palette.common.white : theme.palette.primary.main, cursor: 'pointer' }}
                    onClick={() => handleMenuItemClick(`/${page.toLowerCase()}`)}
                  >
                    {page}
                  </Typography>
                )
              ))}
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Slide>
  );
};

export default AppBarWithLogo;