import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
//
// Define the interface for each item:
//
interface DifferenceItem {
    icon: JSX.Element;
    title: string;
    paragraph: string;
}

//
// Define the props for the BitBlossomDifference component.
//
interface BitBlossomDifferenceProps {
    header?: string;
    items: DifferenceItem[];
    imageSrc : string;
}

//
// Define your styles
//
const h1Style = {
    fontSize: {
        xs: '10px',
        sm: '32px',
        md: '40px',
        lg: '40px', // Same as md
    },
    fontFamily: 'Signika',
    color: 'primary.main',
    lineHeight: {
        xs: 1,
        md: 1.5,
        lg: 1.5,
    },
};

const h2Style = {
    fontSize: {
        xs: '8px',
        sm: '16px',
        md: '20px',
    },
    fontFamily: 'DM Sans',
    lineHeight: {
        xs: 1,
        md: 1.5,
        lg: 1.5,
    },
    fontWeight: 'bold',
    color: 'primary.main',
    mt: { xs: 0, md: 1 },
    mb: { xs: 1, md: 1 },
};

const bodyStyle = {
    fontFamily: 'DM Sans',
    fontSize: {
        xs: '8px',
        sm: '10px',
        md: '16px',
    },
    lineHeight: { xs: 1, md: 1.8 },
};

const iconSizing = {
    fontSize: {
        xs: '10px',
        sm: '24px',
        md: '35px',
    },
    color: 'primary.main',
    mt: { xs: 0, md: 0 },
    mb: { xs: 0, md: 0 },
};

//
// The updated component
//
const BitBlossomDifference: React.FC<BitBlossomDifferenceProps> = ({
    header = 'The Bit Blossom Difference',
    items,
    imageSrc
}) => {
    return (
        <Box
            sx={{
                position: 'relative',
                overflow: 'visible',
                width: '100%',
                mt: { xs: 5, md: 10 },
            }}
        >
            {/* Background Image */}
            <Box
                component="img"
                src="ContactUsBackground.jpg"
                alt="Bit Blossom Difference"
                sx={{
                    display: 'block',
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                    opacity: '60%',
                }}
            />

            <Box
                sx={{
                    position: 'absolute',
                    top: { xs: 10, md: 50 },
                    right: { xs: 10, md: 50 },
                    width: '90%',
                    height: '85%',
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 2,
                    overflow: 'visible',
                }}
            >
                <Box
                    component="img"
                    src="BitBlossom_Logo_Badge.png"
                    alt="Bit Blossom Badge"
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        ml: { xs: -2, md: -5 },
                        mt: { xs: -1, md: -2 },
                        width: { xs: 50, md: 150 },
                    }}
                />
                <Box
                    sx={{
                        flexGrow: 1,
                        flexShrink: 1,
                        flexBasis: { xs: '30%', md: '40%' },
                        padding: { xs: 0, md: 2 },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        component="img"
                        src={imageSrc}
                        alt="Bit Blossom Difference"
                        sx={{
                            maxHeight: '100%',
                            maxWidth: '100%',
                            objectFit: 'contain',
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        flexShrink: 1,
                        flexBasis: { xs: '70%', md: '60%' },
                        padding: 2,
                        mt: { xs: 0, md: 3 },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        textAlign: 'center',
                    }}
                >
                    <Grid container spacing={{ xs: 1, md: 2 }}>
                        <Grid
                            size={{ xs: 12 }}
                            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                        >
                            <Typography sx={{ ...h1Style, mb: { xs: 1, md: 2 } }}>{header}</Typography>
                        </Grid>

                        {items.map((item, index) => (
                            <Grid
                                key={index}
                                size={{ xs: 6 }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    // (Optional: adjust marginBottom per your design)
                                    mb: { xs: 0, md: index % 2 === 1 ? 5 : 0 },
                                }}
                            >
                                {/* Clone the icon element so we can pass in the iconSizing sx prop */}
                                {React.cloneElement(item.icon, { sx: iconSizing })}
                                <Typography sx={{ ...h2Style, color: 'primary.main' }}>{item.title}</Typography>
                                <Typography sx={{ ...bodyStyle, color: 'secondary.main' }}>
                                    {item.paragraph}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default BitBlossomDifference;
