import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, useMediaQuery } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { AnimatePresence, motion } from 'framer-motion';

interface QuotesSectionProps { }

const h1Style = {
    fontSize: {
        xs: '24px',
        sm: '32px',
        md: '40px',
    },
    fontFamily: 'Signika',
    color: 'primary.main',
};

const quoteStyle = {
    fontSize: {
        xs: '16px',
        sm: '20px',
        md: '20px',
    },
    fontFamily: 'Signika',
    color: 'primary.main',
    opacity: '80%'
};


interface Quote {
    text: string;
    author: string;
    role: string;
}


const quotes: Quote[] = [
    {
        text: `Shout out to Sydney for helping fix some kinks on my Shopify site. 
           She is an absolute gem to work with and she fixed my site in a day 
           that literally had me in meltdown for weeks! I will 100% continue 
           to use her with my IT needs`,
        author: 'Chelsea B.',
        role: '',
    },
    {
        text: `Knowledgeable, Authentic, Trustworthy, Hardworking, Professional, Communicable.  They are all these things plus more. 
    They get to know your business and are looking at ways for the business to 
    improve efficiency, customer experience and money. Your search for IT stops here.`,
        author: 'Marilyn M.',
        role: ''
    },
    {
        text: `I cannot recommend her highly enough and will hire her again if and when 
I need any future professional services. You will not go wrong working with her. 
    She is honest, straightforward and truly cares for the work and the people whom 
    she serves. My business is in a much better spot since hiring her.`,
        author: 'Tara A.',
        role: ''
    }
];

const quoteVariants = {
    enter: { opacity: 0, x: 50 },   // Starting state
    center: { opacity: 1, x: 0 },   // Animate to
    exit: { opacity: 0, x: -50 },   // Exit state
};

const QuotesSection: React.FC<QuotesSectionProps> = () => {
    // Track which quote is displayed
    const [activeIndex, setActiveIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    // Move to the next quote (wrapping around)
    const handleNext = () => {
        setActiveIndex((prev) => (prev + 1) % quotes.length);
    };

    // Move to the previous quote (wrapping around)
    const handlePrev = () => {
        setActiveIndex((prev) => (prev - 1 + quotes.length) % quotes.length);
    };

    // Media query, if you want to tweak styles based on screen size
    const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    useEffect(() => {
        let intervalId: NodeJS.Timeout | undefined;

        if (!isHovered) {
            intervalId = setInterval(() => {
                setActiveIndex((prev) => (prev + 1) % quotes.length);
            }, 5000);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [isHovered]);


    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                textAlign: 'center',
                overflowX: 'hidden',
                p: { xs: 0, md: 4 },
                mt: { xs : 30,md:0}
            }}

        >
            {/* Heading */}
            <Typography variant="h1" sx={{ ...h1Style, mb: {xs:0,md:2} }}>
                What They’re Saying
            </Typography>

            {/* Quote navigation & text */}
            <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
                style={{ display: 'inline-block', originX: 0.5, originY: 0.5 }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        mr: { xs : 2,md:10},
                        ml: { xs : 2,md:10},
                        minHeight: '275px',
                    }}
                    onMouseEnter={() => setIsHovered(true && !isSmallScreen)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {/* Left arrow */}
                    <IconButton
                        onClick={handlePrev}
                        sx={{ position: 'absolute', left: 0, transform: 'translateX(-100%)' }}
                    >
                        <ArrowBackIos color="secondary" sx={{ fontSize: 64 }} />
                    </IconButton>

                    {/* Quote content */}
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={activeIndex}
                            variants={quoteVariants}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            transition={{ duration: 0.4 }}
                        >
                            <Box sx={{ mr: {xs:5,md:10}, ml: {xs:5,md:10}, mt: {xs:5,md:10} }}>
                                <Typography
                                    variant={isSmallScreen ? 'body1' : 'h6'}
                                    sx={{ mb: 0, textAlign: 'left', fontSize: 70, fontFamily: 'Signika', lineHeight: 0, ml: -5, color: "secondary.main" }}
                                >
                                    “
                                </Typography>
                                <Typography
                                    variant={isSmallScreen ? 'body1' : 'h6'}
                                    sx={{ ml: 1, mb: 1, ...quoteStyle, textAlign: 'left' }}
                                >
                                    {quotes[activeIndex].text}
                                </Typography>

                                <Typography
                                    variant={isSmallScreen ? 'body1' : 'h6'}
                                    sx={{
                                        mb: 0, textAlign: 'right', fontSize: 70, fontFamily: 'Signika',
                                        lineHeight: 0, mr: -5, color: "secondary.main"
                                    }}
                                >
                                    ”
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    color="secondary"
                                    sx={{ fontFamily: 'DM Sans', fontSize: 16 }}
                                >
                                    {quotes[activeIndex].author}{quotes[activeIndex].role && `, ${quotes[activeIndex].role}`}
                                </Typography>
                            </Box>
                        </motion.div>
                    </AnimatePresence>

                    {/* Right arrow */}
                    <IconButton
                        onClick={handleNext}
                        sx={{ position: 'absolute', right: 0, transform: 'translateX(100%)' }}
                    >
                        <ArrowForwardIos color="secondary" sx={{ fontSize: 64 }} />
                    </IconButton>
                </Box>
            </motion.div>
        </Box>
    );
};

export default QuotesSection;
