import { Helmet } from 'react-helmet-async';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import { Box, Typography, Button } from '@mui/material';
import PackagesSection, { PackageItem } from '../components/ServiceComponents/PackagesSection';
import ProcessSection, {stepText} from '../components/ServiceComponents/ProcessSection';
import BitBlossomDifference from '../components/ServiceComponents/BitBlossomDifference';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';

const h1Style = {
    fontSize: {
        xs: '24px',
        sm: '32px',
        md: '40px',
        lg: '40px' // Make lg the same as md
    },
    fontFamily: 'Signika',
    color: 'primary.main',
    lineHeight: {
        xs: 1,
        md: 1.5,
    },
};

const h2Style = {
    fontSize: {
        xs: '16px',
        sm: '24px',
        md: '30px',
    },
    fontFamily: 'Signika',
    lineHeight: {
        xs: 1,
        md: 1.5,
    },
};

const paragraphStyle = {
    fontFamily: 'DM Sans',
    fontSize: {
        xs: '12px',
        sm: '14px',
        md: '18px',
        lg: '18px',
    },
    lineHeight: 1.8,
};


const IncludedInPackage: PackageItem[] = [
    {
        title: 'Responsive and Mobile-Optimized',
        description: 'Your website will look stunning and function perfectly on all devices, including smartphones and tablets',
    },
    {
        title: 'SEO & Keyword Optimization',
        description: 'We optimize your site with relevant keywords and technical SEO, helping you rank higher on search engines.',
    },
    {
        title: 'Website Back End Setup',
        description: 'We’ll ensure your site is functional and connected with essential features like contact forms, email notifications, and automation tools.',
    },
    {
        title: "Post-Launch Support",
        description: "We’re here for you after launch with two weeks of dedicated support for any questions or adjustments. Need ongoing help? Ask about our optional continuous support packages!"
    }
];

const steps: stepText[] = [
    {
        title: "Step 1: Onboarding",
        description: "We take time to understand your business and map out the ideal software solution by identifying key challenges, goals, and must-have features."
    },
    {
        title: "Step 2: Collaboration & Design",
        description: "We create wireframes and mockups to give you a clear preview of your site’s structure, with your feedback guiding the process."
    },
    {
        title: "Step 3: Development",
        description: "We get to work building your software from the ground up, ensuring every feature is customized and optimized for performance."
    },
    {
        title: "Step 4: Launch & Support",
        description: "Celebrate! Your software is live, and we provide you with the training you need to manage updates easily."
    }
];


const differenceItems = [
  {
    icon: <CheckBoxOutlinedIcon />,
    title: 'Seamless & Stress Free',
    paragraph:
      'We know you’re busy, so we’ve streamlined our process to be smooth and simple—minimizing stress and maximizing results.',
  },
  {
    icon: <FavoriteBorderOutlinedIcon />,
    title: 'We’re in it with you',
    paragraph:
      'Our goal is to be your long-term partner, helping you navigate changes, updates, and growth opportunities.',
  },
  {
    icon: <StarBorderOutlinedIcon />,
    title: 'We LOVE what we do',
    paragraph:
      'Building websites isn’t just work—it’s our passion. We’re genuinely excited to bring your vision to life.',
  },
  {
    icon: <EmojiObjectsOutlinedIcon />,
    title: 'Strategy Meets Creativity',
    paragraph:
      'Every design decision is guided by your brand’s goals to ensure your site is strategic, targeted, and impactful.',
  },
];



const WebDesignServicesPage: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Website Design + Development Services | Bit Blossom Software</title>
                <meta name="description" content="Professional website design and development services including Shopify, Wix, and Squarespace integrations. Get a custom website tailored to your needs." />
            </Helmet>
            <AppBarWithLogo
                logoAlt="Bit Blossom Software Logo"
                logoSrc={`BitBlossom_Logo_Hztl_cropped.png`}
                appName="Bit Blossom Software"
                useDark={false}
            />
            <Box sx={{ overflowX: 'hidden' }}>
                <Box style={{
                    position: 'relative', overflow: 'visible', width: '100%'
                }}>
                    <Box
                        component="img"
                        src="WebDevServicesTopBanner.jpg"
                        alt="Contact Us Top Banner"
                        sx={{
                            display: 'block',
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover',
                            opacity: 0.15,
                            zIndex: 0,
                        }}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: { xs: '100%', md: '75%' },
                            height: '100%',
                            flexDirection: 'column',
                            mt: { xs: 0, md: 10 },
                            ml: { xs: 2, md: 10 },
                            pt: { xs: 2, md: 0 },
                            color: 'white',
                            minHeight: '500px',
                        }}
                    >
                        <Typography
                            component="p"
                            variant="h1"
                            sx={{
                                textAlign: 'left',
                                mb: 1,
                                ...h1Style
                            }}
                        >
                            Website Design + Development
                        </Typography>

                        <Typography
                            component="p"
                            variant="h2"
                            sx={{
                                textAlign: 'left',
                                ...paragraphStyle,
                                color: 'primary.main',
                                opacity: 0.7,
                            }}
                        >
                            Beautiful, Custom Websites Designed to Drive Your Success
                        </Typography>
                    </Box>
                </Box>
                <PackagesSection h2Style={h2Style} 
                                 imageSrc={"iPadWebDev.png"} 
                                 packageItems={IncludedInPackage} 
                                 title={"Website Packages:"}
                                 secondaryTitle='Designed for Your Unique Needs'
                                 imageWidth={{ xs: '30%', md: '45%', lg: '45%' }}/>
                <ProcessSection h1Style={h1Style} stepText={steps}/>
                <BitBlossomDifference header="The Bit Blossom Difference" 
                                      items={differenceItems} 
                                      imageSrc="ContactUsSydneyPic.jpg"
                                      />
                <Box sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', mt: 5, mb: 5 }}>
                    <Typography variant="h1" sx={{ ...h1Style }}>
                        Excited to get started?
                    </Typography>
                    <Typography variant="h1" sx={{ ...paragraphStyle, mt: 2.5, color: 'secondary.main', opacity: 0.80, mb:{sm: 0, md:3} }}>
                        Let’s build something amazing together!
                    </Typography>
                    <Button variant="contained" sx={{ mt: 2.5, color: 'white', backgroundColor: 'primary.main' }} href="/contact">
                        Start Your Project
                    </Button>
                </Box>
                <BottomNav useDark={true} />
            </Box>
        </>
    );
};

export default WebDesignServicesPage;